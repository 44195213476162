.expired{
    display: flex;
    justify-content: center;
    align-items: center;
    .content_wrapper{
        margin-top: 100px;
        .title{
            font-size: 18px;
            font-weight: 500;
        }
        .description{
            margin-top: 10px;
            opacity: 0.7;
        }
    }
}