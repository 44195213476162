.layout {
    position: relative;
    min-height: 100vh;
    max-width: 600px;
    width: 100%;
    margin: auto;
    background-color: #fff;
    position: relative;
    padding-bottom:46px ;
    padding: 30PX;
    padding-bottom: 55px;
    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 9;
    }
}
