.counter{
    background-color: rgba(52, 91, 126, 0.7);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500;
    padding: 5px;
    z-index: 1;
}