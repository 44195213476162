$border-color: rgb(233, 233, 233);
.brokers {
    .title {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 5px;
    }
    .sub_title {
        color: gray;
        line-height: 20px;
        font-size: 14px;
    }
    .brokers_wrapper {
        margin-top: 30px;
        overflow: hidden;
        max-height: 221px;
        &.expanded {
            max-height: 100%;
        }
        .row {
            margin-bottom: -1px;
            .card_wrapper {
                border-bottom: 1px solid $border-color;
                border-right: 1px solid $border-color;
                cursor: pointer;
            }
        }
    }
    .show_more{
        text-align: center;
        padding: 8px 0;
        color: rgb(18, 105, 139);
        font-weight: 600;
        border: 1px solid;
        max-width: 60%;
        margin: auto;
        margin-top: 20px;
    }
}
