.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgb(255 255 255 / 10%);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .box {
        background-color: #fff;
        width: 90%;
        margin: auto;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 2px 2px 15px rgb(158, 158, 158);
        color: gray;
        .body{
            padding: 20px;
            text-align: center;
            .top{
                display: flex;
                justify-content: center;
                align-items:center ;
                margin-bottom: 20px;
                .dots{
                    &::after{
                        content: "- - - - - - - -";
                    }
                }
                .icon{
                    margin: 0 10px;
                    color: rgb(126, 195, 255);
                    svg{
                        width: 30px;
                        height: 30px;
                    }
                    &.rotate{
                        transform: rotate(90deg);
                    }
                }
            }
            .center{
                margin-bottom: 10px;
                font-weight: 500;
                color: #222;
            }
        }
        .footer{
            background-color: aliceblue;
        }
    }
}
