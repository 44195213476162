@import "../../style/var";

.input {
    label {
        margin-bottom: 5px;
        color: rgba(52, 91, 126, 0.7);
        font-weight: 700;
    }
    input {
        width: 100%;
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        padding: 7px 5px;
        &:focus,:hover {
            outline: 1px solid map-get($colors, "secondary");
        }
        &::placeholder {
            color: #bdbdbd7b;
            font-size: 14px;
        }
    }
    .input_help_text{
        font-size: 12px;
        color:#718ca4ba

    }
}
