@import "./style/_var.scss";

.App {
    background-color: aliceblue;
    .counter {
        position: fixed;
        z-index: 99;
        bottom: 50px;
        right: 50px;
    }
}
