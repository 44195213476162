.card {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    position: relative;
    .icon_wrapper {
        max-width: 100%;
        // width: 40px;
        width: 100%;
        height: 40px;
        margin: auto;
        text-align: center;
        img {
            width: auto;
            margin: auto;
            max-height: 100%;
            border-radius: 6px;
        }
    }
    .title {
        margin-top: 10px;
        font-weight: 600;
        line-height: 20px;
        min-height: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: rgb(52 91 126 / 70%);
        font-size: 12px;
    }
    .upcomming {
        position: absolute;
        background: rgba(255, 255, 255, .2);
        backdrop-filter:blur(0.5px);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            position: absolute;
            left: 0;
            right: 0;
            color: #fff;
            font-size: 10px;
            font-weight: 500;
            padding: 0 6px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 4px;
            padding: 5px 2px;
        }
    }
}
