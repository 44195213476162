@import "../../style/var";

.form {
    .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .sub_title {
        color: map-get($colors, "font-gray");
    }
    .form_wrapper {
        margin-top: 30px;
        form {
            > div {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
