@import "../../style/var";

.button {
    text-align: center;
    button {
        width: 100%;
        max-width: 200px;
        background-color: map-get($colors, "secondary");
        color: #fff;
        outline: none;
        border: none;
        padding: 7px 5px;
        border-radius: 8px;
        &:hover{
            opacity: 0.7;
        }
    }
}
